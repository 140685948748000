/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import industryIcon from '../images/Icons/industry.svg';
import teamIcon from '../images/Icons/team.svg';
import technologiesIcon from '../images/Icons/technologies.svg';
import scopeIcon from '../images/Icons/scope.svg';

const ProjectDetails = ({ data }) => {
  const { global } = data;
  return (
    <>
      <Seo seo={data.projects.metaData} global={global} />
      <Layout global={global}>
        <div className="container project-details-container">
          <div className="project-icon-wrapper">
            <img src={data.projects.darkIcon.url} alt={data.projects.title} />
          </div>
          <div>
            {data.projects.projectContainer.map((item) => (
              <div key={item.title}>
                <div className="detail-project-title">
                  <span className="project-description ">
                    <ReactMarkdown>
                      {item.changeableContent}
                    </ReactMarkdown>
                  </span>
                </div>
                <div className="details-container">
                  <div className="details-wrapper">
                    <div className="details-icon">
                      <img
                        alt={item.ProjectDetails.industryTitle}
                        src={industryIcon}
                      />
                    </div>
                    <div className="details-title-wrapper">
                      <h2>{item.ProjectDetails.industryTitle}</h2>
                      <p>{item.ProjectDetails.industry}</p>
                    </div>
                  </div>
                  <div className="details-wrapper">
                    <div className="details-icon">
                      <img
                        alt={item.ProjectDetails.teamSizeTitle}
                        src={teamIcon}
                      />
                    </div>
                    <div className="details-title-wrapper">
                      <h2>{item.ProjectDetails.teamSizeTitle}</h2>
                      <p>{item.ProjectDetails.teamSize}</p>
                    </div>
                  </div>
                  <div className="details-wrapper">
                    <div className="details-icon">
                      <img
                        alt={item.ProjectDetails.technologiesTitle}
                        src={technologiesIcon}
                      />
                    </div>
                    <div className="details-title-wrapper">
                      <h2>{item.ProjectDetails.technologiesTitle}</h2>
                      <p>{item.ProjectDetails.technologies}</p>
                    </div>
                  </div>
                  <div className="details-wrapper">
                    <div className="details-icon">
                      <img
                        alt={item.ProjectDetails.scopeOfWorkTitle}
                        src={scopeIcon}
                      />
                    </div>
                    <div className="details-title-wrapper">
                      <h2>{item.ProjectDetails.scopeOfWorkTitle}</h2>
                      <p>{item.ProjectDetails.scopeOfWork}</p>
                    </div>
                  </div>
                </div>
                <img
                  src={item.image?.url}
                  alt={item.title}
                  className="project-image"
                />
                {data.projects.projectContainer.length > 1 && (
                  <div className="project-divider" />
                )}
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ProjectDetails;

export const query = graphql`
  query GetSingleProject($slug: String) {
    global: strapiGlobal {
      jumpToTop {
        title
      }
      id
      metaData {
        title
        description
        image {
          url
        }
      }
      navbar {
        links {
          url
          title
        }
      }
      footer {
        contactIsmir
        footerInfo
        headerEngagementModels
        headerHome
        headerTeam
        headerTestimonials
        textHome
        textEngagementModels
        textTeam
        textTestimonials
        facebookUrl
        instagramUrl
        linkedinUrl
        dribbbleUrl
      }
    }
    projects: strapiProjects(slug: { eq: $slug }) {
      metaData {
        title
        description
        image {
          url
        }
      }
      slug
      description
      nextProject
      previousProject
      title
      darkIcon {
        url
      }
      icon {
        url
      }
      image {
        url
      }
      projectContainer {
        ProjectDetails {
          industry
          industryTitle
          scopeOfWork
          scopeOfWorkTitle
          teamSize
          teamSizeTitle
          technologies
          technologiesTitle
        }
        title
        content
        changeableContent
        image {
          url
        }
      }
    }
  }
`;
